import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import TicketShop from "components/TicketShop";
import { List } from "components/List";
import { Hero } from "components/Hero";
import { ticketShopScript } from "constants/ticketShopScript";
import { StaticImage } from "gatsby-plugin-image";
import HeroWavesSvg from "../svgs/HeroWaves.svg";
import HeroWavesFlippedSvg from "../svgs/HeroWavesFlipped.svg";
import RidesSvg from "../svgs/Rides.svg";
import ColorsSvg from "../svgs/Colors.svg";
import CutlerySvg from "../svgs/Cutlery.svg";
import YouTube from "react-youtube";

const staticImgProps = {
  alt: "",
  layout: "fullWidth",
  objectFit: "cover",
  objectPosition: "center",
};

const carouselImages = {
  1: {
    node: <StaticImage src="../images/1.jpg" {...staticImgProps} />,
    src: "/1.jpg",
  },
  2: {
    node: <StaticImage src="../images/2.jpg" {...staticImgProps} />,
    src: "/2.jpg",
  },
  3: {
    node: <StaticImage src="../images/3.jpg" {...staticImgProps} />,
    src: "/3.jpg",
  },
  4: {
    node: <StaticImage src="../images/4.jpg" {...staticImgProps} />,
    src: "/4.jpg",
  },
};

function AboutPage(p) {
  const title = "About | Leeds Holi Festival";
  const description = "";
  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        image={{
          node: (
            <StaticImage
              src="../images/1.jpg"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              objectPosition="center"
            />
          ),
          src: "/1.jpg",
        }}
      >
        <div className="home__hero-text">
          <h1>About us</h1>
        </div>
      </Hero>
      <div className="waves">
        <HeroWavesSvg />
      </div>
      <div className="about__content">
        <h2>Festival of Colours</h2>
        <p>
          It celebrates the beginning of spring as well as the triumph of good
          over evil. It is best known around the world for the powder that
          friends and families throw on each other and enjoys the festival of
          love, peace and happiness. Although the festival originated in India,
          it has been adopted in many places around the world with different
          genre of music. Holi is mostly seen as a time for people to get
          together and enjoy themselves. It is purported to be a time when
          friends, families and communities can get together without any concern
          for caste or ethnicity.
        </p>
        <p>
          Red is a sign of love, fertility and matrimony. Yellow is synonymous
          with tumeric, which has medicinal properties. Blue is the colour of
          Lord Krishna, while green symbolises new beginnings. Leeds Holi
          Festival aims at bringing different cultures together with different
          genre of music under 1 roof, 7 hours of open air colour party.
        </p>
      </div>
      <div className="waves waves--flipped">
        <HeroWavesFlippedSvg />
      </div>
    </React.Fragment>
  );
}

export default AboutPage;
